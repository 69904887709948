import './blocks/cards/card-page';
import './blocks/cards/card-banner';
import './blocks/cards/card-banner-large';
import './blocks/cards/card-banner-horizontal';
import './blocks/cards/card-destination';
import './blocks/cards/card-revenue';
import './blocks/cards/card-short-info';
import './blocks/cards/card-box';
import './blocks/cards/bullet-info';

import './blocks/containers/container';
import './blocks/containers/row';
import './blocks/containers/column';
import './blocks/containers/sea-horse-background';
import './blocks/containers/background-image';

import './blocks/content/hero-slider';
import './blocks/content/hero-slide';

import './blocks/content/content-slider';
import './blocks/content/content-slide';

import './blocks/content/pages-slider';
import './blocks/content/pages-slide';
import './blocks/content/socials-section';

import './blocks/content/logo-section';

import './blocks/content/button-outline';
import './blocks/containers/color';
import './blocks/containers/sabra-badge';

//import './blocks/content/special-popup';

import './blocks/tabs/nav-tabs';
import './blocks/tabs/tab-button';
import './blocks/tabs/tab-content';
import './blocks/tabs/tab-pane';
