if (window.wp && window.wp.ajax)
    {
    
        const { registerBlockType } = window.wp.blocks;
        const { __ } = window.wp.i18n;
        const { Fragment, useState } = window.wp.element;
        const { InspectorControls, PanelColorSettings, withColors, getColorClassName, InnerBlocks, CheckboxControl } = window.wp.blockEditor;
    
        const BlockWithColorSettings = (props) =>
        {
            const { textColor, setTextColor } = props;  // Props received from withColors
    
            let divClass = textColor;
    
                if (textColor.slug != undefined)
                {
                    divClass = 'bg-' + (textColor.slug.split('-')[1] || '');
                }
    
            return (
                <Fragment>
                    <InspectorControls>
                        <PanelColorSettings
                            title={__('Color settings')}
                            colorSettings={[
                                {
                                    value: textColor.color,
                                    onChange: setTextColor,
                                    label: __('Background color'),
                                    
                                },
                            ]}
                        />
                    </InspectorControls>
                    <div className={divClass}>
                        <InnerBlocks />
                    </div>
                </Fragment>
            );
        }
    
        registerBlockType('awp/colorsettings', {
            title: __('Background'),
            icon: 'carrot',
            category: 'common',
            attributes: {
                textColor: {
                    type: 'string'
                },
                customTextColor: {
                    type: 'string'
                },
            },
            edit: withColors({ textColor: 'color' })(BlockWithColorSettings),
            save: (props) =>
            {
                const { textColor } = props.attributes;
                let divClass = textColor;
    
                if (textColor != undefined)
                {
                    divClass = 'bg-' + (textColor.split('-')[1] || '');
                }
                return (
                    <div className={divClass} >
                        <InnerBlocks.Content />
                    </div>
                );
            }
        });
    
    }