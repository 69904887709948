if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    const { RawHTML } = window.wp.element;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
            Toolbar,
        ToolbarButton,
        ToolbarGroup,
        SelectControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/hero-slide', {
        // built-in attributes
        title: 'Hero Slide',
        description: 'Custom Hero Slide',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            btnText: {
                type: 'string',
                default: 'Saznaj više'
            },
            headingTag: {
                type: 'string',
                default: 'h2'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                btnText,
                headingTag
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({backgroundImage: image.sizes.full.url});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onBtnTextChange(btnText) {
                setAttributes({btnText: btnText});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Link' }>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                    <PanelBody title={ 'Background Image Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ backgroundImage } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                    <PanelBody title={ 'Slide Content' }>
                        <p><strong>Heading type</strong></p>

                            <SelectControl
                                label="Heading type"
                                value={headingTag}
                                options={ [
                                    {
                                        value: 'h1',
                                        label: 'Heading 1',
                                    },
                                    {
                                        value: 'h2',
                                        label: 'Heading 2',
                                    },
                                    {
                                        value: 'h3',
                                        label: 'Heading 3',
                                    },
                                    {
                                        value: 'h4',
                                        label: 'Heading 4',
                                    },
                                    {
                                        value: 'h5',
                                        label: 'Heading 5',
                                    },
                                    {
                                        value: 'h6',
                                        label: 'Heading 6',
                                    }
                                ]}
                                onChange={ ( newSize ) => setAttributes({'headingTag': newSize}) }
                            />

                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <p><strong>Description</strong></p>
                        <TextControl value={ description } onChange={ onDescriptionChange }></TextControl>
                        <p><strong>Button</strong></p>
                        <TextControl value={ btnText } onChange={ onBtnTextChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="keen-slider__slide">
                    <div class="cover-image" style={{ height: 350, backgroundImage: `url(${backgroundImage})` }}>
                        <div class="container">
                            <a class="content">
                               
                                <RichText 
                                    key="editable" 
                                    tagName={headingTag} 
                                    className="title"
                                    placeholder="Title" 
                                    value={ title }
                                    onChange={ onTitleChange } />
                                 <RichText 
                                    key="editable" 
                                    tagName="div" 
                                    className="description"
                                    placeholder="DESCRIPTION" 
                                    value={ description }
                                    onChange={ onDescriptionChange }/>
                                <div class="cta">
                                    <RichText 
                                        key="editable" 
                                        tagName="div" 
                                        className="cta-content-btn"
                                        placeholder="Saznajte više" 
                                        value={ btnText }
                                        onChange={ onBtnTextChange }/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                btnText,
                headingTag
            } = attributes;
            const CustomTag = `` + headingTag + ``;

            return (
                <div className="keen-slider__slide" >
                    <div className="lozad cover-image" data-background-image={ backgroundImage }>
                        <div className="container">
                            <a href={ link } className="content">
                                <CustomTag className="title">
                                    <RawHTML>
                                        { title }
                                    </RawHTML>
                                </CustomTag>
                                <div className="description">
                                    <RawHTML>
                                        { description }
                                    </RawHTML>
                                </div>
                                <div className={"cta" + (btnText ? '' : 'd-none')}>
                                    <button className="btn cta-button">
                                        { btnText }
                                    </button>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            );
        },
    });
}