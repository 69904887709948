import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;
    const { RawHTML } = window.wp.element;

    const { 
        RichText,
        InspectorControls,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
        SelectControl,
        CheckboxControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/logo-section', {
        // built-in attributes
        title: 'Logo section',
        description: 'Logo section',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            upperText: {
                type: 'string',
                default: 'Preporučujemo',
            },
            lowerText: {
                type: 'string',
                default: 'Aktualna ponuda'
            },
            showLogo: {
                type: 'boolean',
                default: false
            },
            upperTextTag: {
                type: 'string',
                default: 'div'
            },
            lowerTextTag: {
                type: 'string',
                default: 'div'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                upperText,
                lowerText,
                showLogo,
                upperTextTag,
                lowerTextTag,
                classes
            } = attributes;

            // custom functions
            function onUpperTextChange (upperText) {
                setAttributes({upperText: upperText});
            };

            function onShowLogoChange (show) {
                setAttributes({showLogo: show});
            };

            function onLowerTextChange(lowerText) {
                setAttributes({lowerText: lowerText});
            }

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Upper text</strong></p>
                        <TextControl value={ upperText } onChange={ onUpperTextChange }></TextControl>

                        <p><strong>Upper text type</strong></p>
                        <SelectControl
                            label="Upper text type"
                            value={upperTextTag}
                            options={ [
                                {
                                    value: 'div',
                                    label: 'Div',
                                },
                                {
                                    value: 'h1',
                                    label: 'Heading 1',
                                },
                                {
                                    value: 'h2',
                                    label: 'Heading 2',
                                },
                                {
                                    value: 'h3',
                                    label: 'Heading 3',
                                },
                                {
                                    value: 'h4',
                                    label: 'Heading 4',
                                },
                                {
                                    value: 'h5',
                                    label: 'Heading 5',
                                },
                                {
                                    value: 'h6',
                                    label: 'Heading 6',
                                }
                            ]}
                            onChange={ ( newSize ) => setAttributes({'upperTextTag': newSize}) }
                        />
                        <br />

                        <p><strong>Lower text</strong></p>
                        <TextControl value={ lowerText } onChange={ onLowerTextChange }></TextControl>
                        <p><strong>Lower text type</strong></p>
                        <SelectControl
                            label="Lower text type"
                            value={lowerTextTag}
                            options={ [
                                {
                                    value: 'div',
                                    label: 'Div',
                                },
                                {
                                    value: 'h1',
                                    label: 'Heading 1',
                                },
                                {
                                    value: 'h2',
                                    label: 'Heading 2',
                                },
                                {
                                    value: 'h3',
                                    label: 'Heading 3',
                                },
                                {
                                    value: 'h4',
                                    label: 'Heading 4',
                                },
                                {
                                    value: 'h5',
                                    label: 'Heading 5',
                                },
                                {
                                    value: 'h6',
                                    label: 'Heading 6',
                                }
                            ]}
                            onChange={ ( newSize ) => setAttributes({'lowerTextTag': newSize}) }
                        />
                        <br />

                        <p><strong>Show logo</strong></p>
                        <CheckboxControl
                            __nextHasNoMarginBottom
                            label="Show logo"
                            checked={ showLogo }
                            onChange={ onShowLogoChange }
                            />
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="row logo-section">
                    <div class={"col-auto logo-wrap " + ( ! showLogo ? 'd-none' : '')}>
                        <img src="/wp-content/uploads/component-logo.png"></img> 
                    </div>
                    <div class="col">
                        <div class="row text-wrap">
                            <div class="col-12 upper-text">
                                <RichText 
                                    key="editable" 
                                    tagName="div"
                                    className="upper-text"
                                    placeholder="Preporučujemo" 
                                    value={ upperText }
                                    onChange={ onUpperTextChange } />
                            </div>
                            <div class="col-12 lower-text">
                                <RichText 
                                    key="editable" 
                                    tagName="div"
                                    className="lower-text"
                                    placeholder="Aktualna ponuda" 
                                    value={ lowerText }
                                    onChange={ onLowerTextChange } />
                            </div>
                        </div>
                    </div>
                </div>
            ]);
        },

        save({ attributes }) {

            const {
                upperText,
                lowerText,
                showLogo,
                upperTextTag,
                lowerTextTag,
                classes
            } = attributes;

            const UpperTag = `` + upperTextTag + ``;
            const LowerTag = `` + lowerTextTag + ``;

            return (
                <div class={"row logo-section " + classes}>
                    <div className={ "col-auto logo-wrap " + ( ! showLogo ? 'd-none' : '')}>
                        <img className="lozad" data-src="/wp-content/uploads/component-logo.png"></img> 
                    </div>
                    <div className="col">
                        <div className="row text-wrap">
                            <UpperTag className="col-12 upper-text">
                                {upperText }
                            </UpperTag>
                            <LowerTag className="col-12 lower-text">
                                <RawHTML>
                                    { lowerText }
                                </RawHTML>
                            </LowerTag>
                        </div>
                    </div>
                </div>
            );
        },
    });
}


