if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;

    const {
        PanelBody,
        SelectControl,
        TextControl,
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-short-info', {
        // built-in attributes
        title: 'Card Short Info',
        description: 'Custom Card Short Info',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            icon: {
                type: 'string',
                default: 'la-landmark',
            },
            iconClass: {
                type: 'string',
                default: '',
            },
            title: {
                type: 'string',
                default: ''
            },
            text: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                icon,
                iconClass,
                title,
                text,
                viewMore,
            } = attributes;

            // custom functions
            function onIconChange(icon)
            {
                setAttributes({ icon: icon });
            }

            function onIconClassChange(iconClass)
            {
                setAttributes({ iconClass: iconClass });
            }

            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            function onTextChange(text)
            {
                setAttributes({ text: text });
            }

            function onViewMoreChange(viewMore)
            {
                setAttributes({ viewMore: viewMore });
            }

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Settings'}>
                        <p><strong>Icon</strong></p>
                        <SelectControl
                            value={icon}
                            options={[
                                { label: 'Heart hand', value: 'la-hand-holding-heart' },
                                { label: 'Badge', value: 'la-certificate' },
                                { label: 'User shield', value: 'la-user-shield' },
                            ]}
                            onChange={onIconChange}
                        />
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>
                        <br />
                        <p><strong>Text</strong></p>
                        <TextControl value={text} onChange={onTextChange}></TextControl>
                        <br />
                        <p><strong>Icon clss</strong></p>
                        <TextControl value={iconClass} onChange={onIconClassChange}></TextControl>
                        <br />
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="card short-info-card">
                    <div class="card-body text-center">
                        <i class={ "las mb-2 " + icon }></i>
                        <RichText
                            key="editable"
                            tagName="h4"
                            className="card-title"
                            placeholder="TITLE"
                            value={title}
                            onChange={onTitleChange} />
                        <RichText
                            key="editable"
                            tagName="p"
                            className="card-text"
                            placeholder="TEXT"
                            value={text}
                            onChange={onTextChange} />
                    </div>
                </div>
            ]
            );
        },

        save({ attributes })
        {

            const {
                icon,
                iconClass,
                title,
                text,
                viewMore,
            } = attributes;

            let _icon = 'las ' + icon;
            let icon_class = iconClass || _icon;

            return (
                <div className="card short-info-card">
                    <div className="card-body text-center">
                        <i className={'mb-2 ' + icon_class}></i>
                        <h4 className="card-title">{title}</h4>
                        <p className="card-text px-2">
                            {text}
                        </p>
                    </div>
                </div>
            );
        },
    });
}