if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InspectorControls,
        MediaUpload,
        InnerBlocks
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
    } = window.wp.components;

    registerBlockType('custom-gutenberg/background-image', {
        // built-in attributes
        title: 'Background image',
        description: 'Background image',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                image,
                alt,
            } = attributes;

            // custom functions

            function onSelectImage(image) {
                setAttributes({image: image.sizes.full.url});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                </InspectorControls>
                ,

                <div class="cover-image background-image-section" style={ { backgroundImage: `url(${image})` } } >
                    <InnerBlocks />
                </div>
                
            ]
            );
        },

        save({ attributes }) {

            const {
                image,
            } = attributes;

            return (
                <div className="lozad cover-image background-image-section" data-background-image={ image }>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}