module.exports = data => {

    data.perPage = data.perPage || 12;
    data.total = data.total || 0;
    data.displayedPages = data.displayedPages || 5;
    data.currentPage = +data.currentPage || 1;

    const numberOfPages = Math.ceil(data.total / data.perPage);

    let start = Math.max(1, data.currentPage - Math.abs(Math.floor(data.displayedPages / 2)));
    let end = start + data.displayedPages;

    if (end > numberOfPages) {
        end = numberOfPages + 1;
        start = Math.max(1, end - data.displayedPages);
    }

    const pages = [];

    for (let i = start; i < end; i++) {
        pages.push(i);
    }


    return `            
            <div class="pagination justify-content-center append-to-list ${ (data.currentPage + 1) == end ? `d-none` : `` }" data-page="${(data.currentPage+1)}">
               <button class="btn btn-primary rounded-pill text-white fw-normal">
                    <div class="d-flex align-items-center gap-2">
                        <div>Vidi više putovanja</div>
                        <i class="las la-angle-double-down"></i>
                    </div>
               </button>
            </div>
    `;
};