import { type } from "jquery";

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
        TextareaControl,
        SelectControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-revenue', {
        // built-in attributes
        title: 'Card Revenue',
        description: 'Custom Card Revenue',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            },
            revenue: {
                type: 'string',
                default: ''
            },
            revenueDesc: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            daysFrom: {
                type: 'string',
                default: ''
            },
            daysTo: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                image,
                alt,
                revenue,
                revenueDesc,
                title,
                daysFrom,
                daysTo,
                description
            } = attributes;

            function onSelectImage (image) {
                setAttributes({image: image.sizes.full.url});
            }
            function onAltChange(alt) {
                setAttributes({alt: alt});
            }
            function onRevenueChange(revenue) {
                setAttributes({revenue: revenue});
            }
            function onRevenueDescChange(revenueDesc) {
                setAttributes({revenueDesc: revenueDesc});
            }
            function onTitleChange(title) {
                setAttributes({title: title});
            }
            function onDaysFromChange(daysFrom) {
                setAttributes({daysFrom: daysFrom});
            }
            function onDaysToChange(daysTo) {
                setAttributes({daysTo: daysTo});
            }
            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                        <br />
                        <p><strong>Alt image</strong></p>
                        <TextControl value={ alt } onChange={ onAltChange }></TextControl>
                        <br />

                        <p><strong>Prihod (9.000 € - 12.000 €)</strong></p>
                        <TextControl value={ revenue } onChange={ onRevenueChange }></TextControl>
                        <br />

                        <p><strong>Prihod opis</strong></p>
                        <TextControl value={ revenueDesc } onChange={ onRevenueDescChange }></TextControl>
                        <br />

                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <br />

                        <p><strong>Dani od</strong></p>
                        <TextControl value={ daysFrom } onChange={ onDaysFromChange }></TextControl>
                        <br />

                        <p><strong>Dani do</strong></p>
                        <TextControl value={ daysTo } onChange={ onDaysToChange }></TextControl>
                        <br />

                        <p><strong>Description</strong></p>
                        <TextControl value={ description } onChange={ onDescriptionChange }></TextControl>
                        <br />

                    </PanelBody>
                </InspectorControls>
                ,
                <div className={ "card card-revenue "}>
                    <div className="card-img-top">
                        <img className="lozad cover-image" data-src={ image } alt={ alt } />
                        <div className="card-revenue-cta p-1">
                            <div className="d-flex">
                                <div className="flex-1 ps-4 d-flex flex-column justify-content-center">
                                    <RichText 
                                        key="editable" 
                                        tagName="h5"
                                        className="mb-0"
                                        value={ revenue }
                                        onChange={ onRevenueChange } />
                                    <div className="text-muted small">
                                        <RichText 
                                            key="editable" 
                                            tagName="small"
                                            value={ revenueDesc }
                                            onChange={ onRevenueDescChange } />
                                    </div>
                                </div>
                                <div>
                                    <div className="euro-circle">
                                        <i className="la la-euro-sign la-lg"></i>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="card-body">
                        <RichText 
                            key="editable" 
                            tagName="h5"
                            className="card-title"
                            value={ title }
                            onChange={ onTitleChange } />
                        <div className="d-flex align-items-center">
                            <i className="la la-calendar la-2x me-2"></i>
                            <RichText 
                                key="editable" 
                                tagName="strong"
                                className="text-warning"
                                value={ daysFrom }
                                onChange={ onDaysFromChange } />
                            <span className="mx-1">-</span>
                            <RichText 
                                key="editable" 
                                tagName="strong"
                                className="text-warning"
                                value={ daysTo }
                                onChange={ onDaysToChange } />
                            <small className="ms-2 fw-bold">rezerviranih dana</small>
                        </div>
                        <div className="text-muted small">
                            <RichText 
                                key="editable" 
                                tagName="small"
                                value={ description }
                                onChange={ onDescriptionChange } />
                        </div>
                    </div>
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                image,
                alt,
                revenue,
                revenueDesc,
                title,
                daysFrom,
                daysTo,
                description
            } = attributes;

            return (
                <div className={ "card card-revenue "}>
                    <div className="card-img-top">
                        <img className="lozad cover-image" data-src={ image } alt={ alt } />
                        <div className="card-revenue-cta p-1">
                            <div className="d-flex">
                                <div className="flex-1 ps-4 d-flex flex-column justify-content-center">
                                    <h5 className="mb-0">{ revenue }</h5>
                                    <div className="text-muted small">
                                        <small>{ revenueDesc }</small>
                                    </div>
                                </div>
                                <div>
                                    <div className="euro-circle">
                                        <i className="la la-euro-sign la-lg"></i>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{ title }</h5>
                        <div className="d-flex align-items-center">
                            <i className="la la-calendar la-2x me-2"></i>
                            <strong className="text-warning">{ daysFrom }</strong>
                            <span className="mx-1">-</span>
                            <strong className="text-warning">{ daysTo }</strong>
                            <small className="ms-2 fw-bold">rezerviranih dana</small>
                        </div>
                        <div className="text-muted small">
                            <small>{ description }</small>
                        </div>
                    </div>
                </div>
            );
        },
    });
}