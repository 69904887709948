import functions from '../functions.js';
import { details } from './details.js';
import { DetailsCart } from './details-cart.js';
import {DeparturePicker} from "../components/departure-picker.js";
import Slider from "../components/Slider";


$(() => {
    detailsPackage.init();
})

const detailsPackage = {
    $details: null,
    $form: null,
    form: null,
    dayAndMonthsData: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        aDateFrom: null, 
        aDateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
        time: null,
        dynamicPackage: 1,
    },
    defaults: {
        guests: null, 
        dates: null,
        dateFrom: null, 
        time: null,
        aDateFrom: null,
        aDateTo: null,
        dateTo: null, 
        objectGroupId: 1,
        dynamicPackage: 1,
    },
    init() {
        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        
        if(! this.$details[0] || this.params.objectGroupId != 1) {
            return;
        }
        this.defaults.dynamicPackage = +this.$details.attr('data-dynamicpackage');

        // if(+this.$details.attr('data-dynamicpackage') != this.params.dynamicPackage) {
        //     return;
        // }

        if (+this.$details.attr('data-dynamicpackage') == 0) return;

        this.params.publicId = this.$details.attr('data-objectid');
        this.params.id = this.$details.attr('data-id');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;
        this.params.isBrosTravel = $('[data-isBrosTravel]').length ? $('[data-isBrosTravel]').attr('data-isBrosTravel') : null;

        this.getParams();

        this.$form = $('.availability-form');
        this.form = this.$form[0];
        
        // const departures = JSON.parse($('#details-datepicker').attr('data-departures'));
        // this.setDefaultDeparture(departures);
        // functions.setFormData(this.form, this.params);

        // this.showFlightTickets();
        //this.setPrices();
        $.post('/services/dynamic_package_availability_form_data/', {
            objectId: this.params.id,
            dynamicPackage: this.params.dynamicPackage,
            language: functions.getLang(),
        }).always(response => {
            response = response ? JSON.parse(response) : null;
            if (response.status) {

                const prepared = this.getPackageDepartures(response.data.items, response.data.flights);
                const serviceCodes = response.data.serviceCodes;
                const $service = $("[name='serviceCode']");
                this.enable = prepared.enable;
                this.flights = prepared.flights;
                this.prices = prepared.prices;

                serviceCodes.forEach((serviceCode, key) => {
                    this.allowedServices.forEach((allowedService) => {
                        if (allowedService.key == serviceCode){
                            serviceCodes[key] = allowedService;

                            if ( ! this.params.serviceCode) {
                                this.params.serviceCode = allowedService.key;
                                this.params.serviceCodeId = allowedService.id;
                            }
                        }
                    });
                });

                $("#details-datepicker").attr('data-departures', JSON.stringify(this.enable));
                $("#details-datepicker").attr('data-flights', JSON.stringify(this.flights));

                $("#details-datepicker").attr('data-prices', JSON.stringify(this.prices));

                // $('.datepicker.departure-picker [data-input]').each((i, elem) => {
                //     elem._departurePicker = new DeparturePicker(elem);
                // });

                $service.select2({
                    data: serviceCodes,
                    minimumResultsForSearch: -1
                });
                setTimeout(() => $service.val(this.params.serviceCodeId).trigger('change'));

                this.setDefaultDeparture(this.enable);

                this.renderPeriods();
                this.initDefault();

                // // console.log(this.params.time);
                // // functions.setFormData(this.form, this.params, false);
                // // debugger;
                // // console.log(this.params.time);

                this.showFlightTickets();
                this.setPrices();

                functions.setFormData(this.form, this.params, false);

                this.getCalculation();
            }
        })

        this.params.adultsOnly = $('.input-guests').attr('data-adultsonly');

        if(this.params.guests) {

            this.params.guests = this.params.adultsOnly ? this.params.guests.split(',')[0] + ',0' : this.params.guests;

            $('[name=guests]').each((index, elem) => {
                $(elem).val(this.params.guests);

                let guests = this.params.guests.split(',');
                let i = 1;
                for (let index = 2; index < guests.length; index++) {
                    if(guests[index]) {
                        $('[name=child-age-'  + i + ']').val(guests[index]);
                    }
                    i++;
                }
            });
            $('[name=guests]').trigger('change');
        }
        this.$form.on('submit', e => {
            e.preventDefault();
            e.stopPropagation();
        });
        this.$form.on('change', e => {

            e.preventDefault();
            const $target = $(e.currentTarget);
            const data = functions.getFormData($target);

            // this.params.aDateFrom = data.aDateFrom;
            // this.params.aDateTo = data.aDateTo;
            // this.params.dateFrom = data.dateFrom;
            // this.params.dateTo = data.dateTo;
            this.params.guests = data.guests;
            // this.params.serviceCode = data.serviceCode;
            // this.params.time = data.time;



            functions.setUrlData(
                {'dateFrom': this.params.dateFrom, 
                'dateTo': this.params.dateTo, 
                'aDateFrom': this.params.aDateFrom, 
                'aDateTo': this.params.aDateTo, 
                'guests': this.params.guests, 
                'serviceCode': this.params.serviceCode, 
                'time': this.params.time || '',
                'duration': this.params.duration || ''
                }, true, true
            );
            //this.getCalculation();
        });

        // check availability on form change (not only on btn click)
        $('[name="dateFrom"], [name="dateTo"], [name=guests], [name=serviceCode]').on('change', () => {
            const data = functions.getFormData(this.$form);
            if ( data.dateFrom && data.dateTo && data.guests ) {
                this.$form.trigger('submit');
            }
        });

        if(! this.params.serviceCode && $('[name="serviceCode"]').length) {
            this.params.serviceCode = $('[name="serviceCode"]').val();
        }

        // this.getCalculation();

        this.$cart = $('.details-cart');
        this.cart = this.$cart.length ? new DetailsCart(this.params) : [];

        $(window).on('hashchange', e => {
            this.getParams();
            this.params.guests = this.params.adultsOnly && this.params.guests ? this.params.guests.split(',')[0] + ',0' : this.params.guests;

            this.showFlightTickets();
            this.setPrices();
            this.printDates();

            this.getCalculation();
        });

        if(this.$cart.length) {
            $('.btn-booking').on('click', e => {
                e.preventDefault();
    
                if(! this.cart.cartItems.length) {
                    $('#detailsCartModal').modal('show');
                    setTimeout(() => {
                        $('#detailsCartModal').modal('hide');
                    }, 2500);
                }
            });
        }

    },
    setPrices() {

        let guests = this.params.guests && ! this.params.guests.startsWith('0');
        
        if(! guests) {
            let prices = JSON.parse($('[data-prices]').attr('data-prices'));
            prices = prices[this.params.dateFrom + ',' + this.params.dateTo];
            this.loader();
    
            $('.unit').attr('style', 'order: 10000');
            if(prices) {
                setTimeout(() => {
                    Object.entries(prices).forEach(item => {
                        const unitId = item[0];
    
                        const flightUnitId = $('.flight-ticket.active').attr('data-flightunitid');
                        const rate = item[1][flightUnitId];
            
                        const $unit = $('.unit[data-unitid="' + unitId + '"]');
                        
                        $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                        $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);
    
                        let price = rate.priceWithDiscount ? rate.priceWithDiscount : rate.price;
                        price = price.replace('€ ', '');
                        price = price.replace('.', '');
                        let order = parseInt(price);
                        $unit.prop('style', 'order: ' + order);
                        
                        $unit.find('.fm-discount').toggleClass('d-none', ! rate.discountTitle);
    
                        $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                        $unit.find('.fm-discount-title').toggleClass('d-none', rate.validUntil != null);
    
                        $unit.find('.fm-discount-valid-until').toggleClass('d-none', ! rate.validUntil);
                        $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');
            
                        $unit.find('.unit-price-payment-key').text(rate.paymentKey ? rate.paymentKey : '');
                    });
                }, 800);
            }
        }

    },
    showFlightTickets() {

        // $('.flight-ticket').addClass('d-none');
        
        // let dateFrom = this.params.dateFrom;
        // let dateTo = this.params.dateTo;
        // let time = this.params.time;

        // const $tickets = $('.flight-ticket[data-datefrom="' + dateFrom + '"][data-dateto="' + dateTo + '"]');
        
        // $tickets.removeClass('d-none');

        // let $ticket = $tickets.first();
        // $tickets.each((index, ticket) => {
        //     if ($(ticket).attr('data-time') == time) {
        //         $ticket = $(ticket);
        //         return false;
        //     }

        //     $ticket = $(ticket).hasClass('show-first') ? $(ticket) : $ticket;
        // });

        const dataFlights = JSON.parse($('[data-flights]').attr('data-flights'));

        let dateFrom = this.params.dateFrom;
        let dateTo = this.params.dateTo;
        let flights = dataFlights.filter(flight => {
            return flight.dateFrom == dateFrom && flight.dateTo == dateTo;
        });

        this.renderFlightTickets(flights);
        // set active ticket
        this.setFlightTicket(flights);
    },
    setFlightTicket(flights) {
        // $('.flight-ticket').removeClass('active');
        // if($tickets) {
        //     $tickets.addClass('active')
        //     this.setUnitRateIds($tickets);
        // }

        // $('.flight-ticket').on('click', e => {
        //     $('.flight-ticket').removeClass('active');
        //     const $target = $(e.currentTarget);
        //     $target.addClass('active');

        //     this.setUnitRateIds($target);
        //     this.getParams();

        //     $('[name="time"]').val($target.attr('data-time'));
        //     $('[name="aDateFrom"]').val($target.attr('data-adatefrom'));
        //     $('[name="aDateTo"]').val($target.attr('data-adateTo'));

        //     this.params.time = $('[name="time"]').val();
        //     this.params.aDateFrom = $('[name="aDateFrom"]').val();
        //     this.params.aDateTo = $('[name="aDateTo"]').val();

        //     this.$form.change();
        // });

        this.setUnitRateIds(flights[0]);

        $('.flight-ticket').on('click', e => {
            $('.flight-ticket').removeClass('active');
            const $target = $(e.currentTarget);
            $target.addClass('active');

            this.setUnitRateIds(JSON.parse($target.attr('data-flight')));
            this.getParams();
            this.getCalculation();
        });
    },
    setUnitRateIds(flight) {
        // if($target.attr('data-rateids')) {
            const rateIds = flight.rateIds;
            const flightId = this.params.flightId = flight.flightId;
            const flightUnitId = this.params.flightUnitId = flight.flightUnitId;
            Object.entries(rateIds).forEach(item => {
                const unitId = item[0];
                const rateId = item[1];
    
                $('.add-to-cart[data-unitid=' + unitId + ']').attr('data-rateid', rateId);
                $('.add-to-cart[data-unitid=' + unitId + ']').attr('data-flightid', flightId);
                $('.add-to-cart[data-unitid=' + unitId + ']').attr('data-flightunitid', flightUnitId);
    
                $('.btn-booking[data-unitid=' + unitId + ']').attr('data-rateid', rateId);
                $('.btn-booking[data-unitid=' + unitId + ']').attr('data-flightid', flightId);
                $('.btn-booking[data-unitid=' + unitId + ']').attr('data-flightunitid', flightUnitId);
            });
        // }
    },
    setDefaultDeparture(departures) {
        this.params.dateFrom = ! this.params.dateFrom && this.departures ? this.departures[Object.keys(this.departures)[0]].dateFrom : this.params.dateFrom;
        this.params.dateTo = ! this.params.dateTo && this.params.dateFrom && this.departures ? this.departures[Object.keys(this.departures)[0]].dateTo : this.params.dateTo;

        this.params.aDateFrom = ! this.params.aDateFrom && departures.length ? this.departures[Object.keys(this.departures)[0]].aDateFrom : this.params.aDateFrom;
        this.params.aDateTo = ! this.params.aDateTo && this.params.aDateFrom && departures.length ? this.departures[Object.keys(this.departures)[0]].aDateTo : this.params.dateTo;

        this.params.duration = ! this.params.duration && this.params.duration && departures.length ? this.departures[Object.keys(this.departures)[0]].duration : this.params.duration;

        let departure = this.departures ? this.departures[this.params.dateFrom + ',' + this.params.dateTo] : null;
        departure && (this.params.time = departure.time);
    },
    printDates() {
        $('.unit-departure').addClass('fade');
        setTimeout(() => {
            $('.unit-departure').removeClass('fade');
            $('.date-from').text(functions.formatDate(this.params.dateFrom));
            $('.date-to').text(functions.formatDate(this.params.dateTo));
            $('.overnights').text($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']').attr('data-duration'));
        }, 800);

    },
    getParams() {
        this.params = details.getParams(this.params, this.defaults);
        this.params.guests = this.params.guests && this.params.guests.startsWith('0') ? null : this.params.guests;
    },
    getCalculation(params) {
        const cParams = params ? params : this.params;
        const calculation = details.getCalculation(cParams);
        if(calculation) {
            this.loader(this.params.unitId ? $('.unit[data-unitid=' + this.params.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response, cParams);
                }).fail(error => {
                });
        } else {

            this.setPrices();

            $('.btn-booking').toggleClass('d-none', true);
            $('.add-to-cart').toggleClass('d-none', true);

            $('.no-period-label').toggleClass('d-none', false);
            $('.availablility-label').toggleClass('d-none', true);

            $('.unit-error-container').toggleClass('d-none', true);
            $('.unit-price').removeClass('d-none');
            $('.unit-price-total-label').toggleClass('d-none', true);
            $('.unit-price-label').toggleClass('d-none', false);

            $('.unit-price-payment-key').toggleClass('d-none', false);
        }
    },
    calculationDone(response, params) {
        if(response.status) {
            const data = response.data || [];

            //console.log(data);

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');
                const $addToCart = $unit.find('.add-to-cart');

                let discountTitle = null;

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    priceOld = +priceOld.toFixed(2);
                    discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').toggleClass('d-none', false);
                    }

                    $unit.find('.unit-price-old').text(priceOld > total ? currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',') : '');
                    
                    //$unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));
                    
                    //this.printDates();

                    if(this.$cart.length) {
                        $addToCart.attr('data-unitcarttotal', total);
                        $addToCart.attr('data-unitcarttotalold', priceOld);
                        $addToCart.attr('data-currencysymbol', currencySymbol);
    
                        $addToCart.attr('data-datefrom', params.dateFrom);
                        $addToCart.attr('data-dateto', params.dateTo);
                        $addToCart.attr('data-guests', params.guests);

                        $addToCart.attr('data-servicecode', params.serviceCode);
                        $addToCart.attr('data-flightunitid', params.flightUnitId);
                        $addToCart.attr('data-time', params.time);
                    } else {
                        const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']');
                        //const rateId = $btnBooking.attr('data-rateid');
                        //const flightId = $btnBooking.attr('data-flightid');
                        const flightUnitId = $btnBooking.attr('data-flightunitid');
    
                        $btnBooking.text(unitData.booking.status == 'Request' ? $btnBooking.attr('data-requestLabel') : $btnBooking.attr('data-bookingLabel'));

                       let pricelistId = $unit.attr('data-pricelistid');

                        let cartData = [];

                        cartData.push({
                            'unitId': unitId,
                            'dateFrom': this.params.dateFrom,
                            'dateTo': this.params.dateTo,
                            'guests': this.params.guests,
                            'aDateFrom': this.params.aDateFrom,
                            'aDateTo': this.params.aDateTo,
                            'pricelistId': pricelistId,
                            'dynamicPackage': this.params.dynamicPackage,
                            'flightUnitId': flightUnitId,
                            'time' : this.params.time
                        }); 

                        if ($btnBooking.attr('href')) {
                            $btnBooking.attr('href',
                                $btnBooking.attr('href').split('?')[0] +
                                '?unitId=' + unitId +
                                '&dateFrom='+ this.params.dateFrom +
                                '&dateTo=' + this.params.dateTo +
                                '&guests=' + this.params.guests +
                                '&pricelistId=' + pricelistId +
                                '&aDateFrom=' + this.params.aDateFrom +
                                '&aDateTo=' + this.params.aDateTo +
                                '&dynamicPackage=' + this.params.dynamicPackage +
                                '&flightUnitId=' + (flightUnitId || '') +
                                '&time=' + this.params.time || '' + '&addAllOptionals=true'
                            );
                        }

    
                        // $btnBooking.on('click', e => {
                        //     e.preventDefault();
                        //     location = $(e.currentTarget).attr('data-href');
                        // });
                    }

                    $unit.prop('style', 'order: ' + parseInt(total));

                } else {
                    $unit.prop('style', 'order: 10000;');
                }

                $unit.find('.unit-price-total-label').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-label').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.unit-price-payment-key').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.fm-discount').toggleClass('d-none', discountTitle == null || ! details.isBookable(unitData));
                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', details.isBookable(unitData));
                //$unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                // $unit.find('.no-period-label').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.btn-booking').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.add-to-cart').toggleClass('d-none', ! details.isBookable(unitData));

                //$unit.find('.btn-booking-container').toggleClass('d-none', ! details.isBookable(unitData));
                //$unit.find('.btn-request-nobookable').toggleClass('d-none', details.isBookable(unitData));

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);

                $unit.find('.availablility-label').toggleClass('d-none', unitData.message != 'notAvailable');
                //$unit.find('.no-period-label').toggleClass('d-none', unitData.message == 'notAvailable' || details.isBookable(unitData));
                $unit.find('.no-period-label').toggleClass('d-none', true);
                $unit.find('.unit-price-label').toggleClass('d-none', true);
                params.pricelistId = params.pricelistId ? params.pricelistId : $unit.attr('data-pricelistid');
            });

            if(this.$cart.length) {
                const $btnBooking = $('.btn-booking');
    
                $btnBooking.on('click', e => {
                    e.preventDefault();
                    let cartData = [];
    
                    let url = $(e.currentTarget).attr('data-href') + 
                    '?unitId=' + unitId +
                    '&dateFrom='+ this.params.dateFrom +
                    '&dateTo=' + this.params.dateTo +
                    '&guests=' + this.params.guests +
                    '&pricelistId=' + pricelistId +
                    '&dynamicPackage=' + this.params.dynamicPackage +
                    '&flightUnitId=' + flightUnitId +
                    '&time=' + this.params.time + '&addAllOptionals=true';

                    if(this.cart.cartItems.length) {
                        location = url;
                    } else {
                        $('#detailsCartModal').modal('show');
                        setTimeout(() => {
                            $('#detailsCartModal').modal('hide');
                        }, 2500);
                    }

                    //location = url;
                });
            }
        }
    },
    loader($target = null) {
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 800);
    },

    // Added for ajax load of dapartures and prices
    getPackageDepartures(rates, flightTitles) {
        let departures = {};

        for (let [key, rate] of Object.entries(rates)) {
            let unique = rate.dateFrom + ',' + rate.dateTo;

            let flight = rate.flight;
            let flightUnitId = flight.unitId;
            let unitId = rate['unitId']['id'];

            if ( ! departures[unique])
            {
                let flightIds = {};
                flightIds[flightUnitId] = {};
                flightIds[flightUnitId][unitId] = rate.id;

                let flights = {};
                flights[flightUnitId] = flight;

                let rates = {};
                rates[rate.id] = rate;

                const date1 = new Date(rate.dateFrom);
                const date2 = new Date(rate.dateTo);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                
                departures[unique] = {
                    'dateFrom'  : rate.dateFrom,
                    'dateTo'    : rate.dateTo,
                    'aDateFrom' : rate.aDateFrom || '',
                    'aDateTo'   : rate.aDateTo || '',
                    'time'      : flight.timeDepartureFrom || '',
                    'duration'  : rate.accommodationDuration,
                    'flightIds' : flightIds,
                    'flights'   : flights,
                    'rates'     : rates,
                    'nights'    : diffDays
                };
            }
            else
            {
                let departure = departures[unique];

                if ( ! departure.flightIds[flightUnitId])
                {
                    let flightIds = {};
                    flightIds[flightUnitId] = {};
                    flightIds[flightUnitId][unitId] = rate.id;

                    departure.flightIds[flightUnitId] = {};
                    departure.flightIds[flightUnitId][unitId] = rate.id;
                }
                else if ( ! departure.flightIds[flightUnitId][unitId])
                {
                    departure.flightIds[flightUnitId][unitId] = rate.id;
                }

                departure.flights[flightUnitId] = flight;
                departure.rates[rate.id] = rate;
            }
        }
      

        this.departures = departures;
        let enable = [];
        let prices = {};
        let flights = [];

        Object.keys(departures).forEach(function(index) {

            let rate = departures[index];

            enable.push({
                dateFrom : rate['dateFrom'],
                dateTo : rate['dateTo'],
                aDateFrom : rate['aDateFrom'],
                aDateTo : rate['aDateTo'],
                duration : rate['duration'],
                nights : rate['duration'],
                time: rate['time']
            })

            prices[index] = detailsPackage.getDeparturePrices(rate);

            let arrival = rate.dateFrom;
            let departure = rate.dateTo;

            let arrivalDay = detailsPackage.getWeekDay(arrival);
            let arrivalDayDeparture = detailsPackage.getWeekDay(departure);

            Object.keys(rate.flights).forEach(function(index) {

                let flight = rate.flights[index];

                let flightUnitId = flight['unitId'];
                let rateIds = rate['flightIds'][flightUnitId];

                let timeDepartureFrom = flight['timeDepartureFrom'];
                let timeDepartureTo = flight['timeDepartureTo'];
                let timeReturnFrom = flight['timeReturnFrom'];
                let timeReturnTo = flight['timeReturnTo'];
                let placeFromName = flight['placeFrom'];
                let placeToName = flight['placeTo'];

                let flightTitle;
                flightTitles.forEach((ft) => {
                    if (ft.id == flight.objectId) {
                        flightTitle = ft.supplierTitle;
                    }
                });

                flights.push({
                    dateFrom              : rate.dateFrom,
                    dateTo                : rate.dateTo,
                    flightId              : flight.objectId,
                    flightTitle           : flightTitle,
                    flightUnitId          : flight.unitId,
                    rateIds               : rateIds,
                    arrivalDay            : arrivalDay,
                    arrivalDayDeparture   : arrivalDayDeparture,
                    timeDepartureFrom     : timeDepartureFrom,
                    timeDepartureTo       : timeDepartureTo,
                    timeReturnFrom        : timeReturnFrom,
                    timeReturnTo          : timeReturnTo,
                    placeFromName         : placeFromName,
                    placeToName           : placeToName,
                });
            });
        });

        return {
            enable : enable,
            prices : prices,
            flights : flights
        };
    },
    getWeekDay(date) {

        const d = new Date(date);
        const days = [
            "nedelja",
            "ponedeljak",
            "utorak",
            "sreda",
            "četvrtak",
            "petak",
            "subota"
        ];

        let day = d.getDay()

        return days[day];
    },
    getDeparturePrices(rate) {
        let departurePrices = {};
        let flights = rate.flightIds;
        let rates = rate.rates;

        Object.keys(flights).forEach(function(flightId) {
            let items = flights[flightId];
            Object.keys(items).forEach(function(unitId) {
                let rateId = items[unitId];
                let unitRate = rates[rateId];

                if (unitRate) {

                    let validUntil = null;
                    if (unitRate.specialDiscount && unitRate.specialDiscount.validUntil) {
                        validUntil = unitRate.specialDiscount.validUntil;
                    }

                    var flightUnitId = flightId;

                    if ( ! departurePrices[unitId]) departurePrices[unitId] = {};

                    departurePrices[unitId][flightUnitId] = {
                        price : '€ ' + functions.formatMoney(unitRate.price, 0, '', ''),
                        priceWithDiscount : unitRate.priceWithDiscount ? ('€ ' + functions.formatMoney(unitRate.priceWithDiscount, 0, '', '')) : null,
                        discountTitle : unitRate.specialDiscount && unitRate.specialDiscount.itemDefinitionId.text ? unitRate.specialDiscount.itemDefinitionId.text : null,
                        validUntil : validUntil,
                        paymentKey : unitRate.paymentTypeKey == 'Once' ? 'po boravku' : 'po osobi'
                    };
                }
            });
        });

        return departurePrices;
    },

    renderFlightTickets(flights) {
        const $flights = $('.flights').empty();

        let transferDepartureIcon = this.params.dynamicPackage == 2 ? 'la-bus' : 'la-plane-departure';
        let transferArrivalIcon = this.params.dynamicPackage == 2 ? 'la-bus' : 'la-plane-arrival';
        flights.forEach((flight, index) => {
            $flights.append(`
                <div class="flight-ticket ${ index == 0 ? 'active' : '' } mb-3 pointer"
                    data-flight='${ JSON.stringify(flight) }' 
                    data-flightId="${ flight.flightId }"
                    data-flightUnitId="${ flight.flightUnitId }"
                    data-rateIds="${ flight.rateIds }"
                    data-dateFrom="${ flight.dateFrom }"
                    data-dateTo="${ flight.dateTo }">
                    <div class="row g-0 flight-ticket-body">
                        <div class="col">
                            <div class="flight-ticket-content">
                                <div class="row">
                                    <div class="ticket-header col-12 row justify-content-between">
                                        <div class="col-lg">
            
                                            <div class="departure-header">
                                                <i class="las ${ transferDepartureIcon } la-2x"></i>
                                                <div class="departure-label">Odlazak</div>
                                                <div class="departure-date" data-flight-{{ $flightUnitId }}-DateFrom>${ functions.formatDate(flight.dateFrom) }</div>
                                                <div class="departure-day" data-flight-{{ $flightUnitId }}-DateFromDay>${ flight.arrivalDay }</div>
                                            </div>
            
                                        </div>
                                        <div class="col-lg">
            
                                            <div class="return-header">
                                                <i class="las ${ transferArrivalIcon } la-2x"></i>
                                                <div class="return-label">Povratak</div>
                                                <div class="return-date" data-flight-{{ $flightUnitId }}-DateTo>${ functions.formatDate(flight.dateTo) }</div>
                                                <div class="return-day" data-flight-{{ $flightUnitId }}-DateToDay>${ flight.arrivalDayDeparture }</div>
                                            </div>
            
                                        </div>
                                    </div>
                                    <div class="col-lg">

                                        <div class="flight-ticket-departure">
                                            
                                            <div class="row gx-2 flex-nowra justify-content-between">
                                                <div class="col-lg-auto col-sm-auto col-12 d-flex align-items-cente flex-column">
                                                    <div class="departure-place-from">${ flight.placeFromName }</div>
                                                    ${ flight.timeDepartureFrom ? '<div class="departure-time-from" data-flight-{{ $flightUnitId }}-TimeDepartureFrom>' + flight.timeDepartureFrom + '</div>' : '' }
                                                    
                                                </div>

                                                <div class="col-sm-auto col d-sm-flex flex-column justify-content-cente align-items-center">
                                                    <i class="las la-arrow-right text-muted la-lg mt-lg-1 my-2"></i>
                                                </div>
                                                
                                                <div class="col-lg-auto col-sm-auto col-12 text-sm-end d-flex align-items-cente flex-column">
                                                    <div class="departure-place-to">${ flight.placeToName }</div>
                                                    ${ flight.timeDepartureTo ? '<div class="departure-time-to" data-flight-{{ $flightUnitId }}-TimeDepartureTo>' + flight.timeDepartureTo + '</div>' : '' }
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-auto">
                                        <div class="d-none d-lg-block h-100 bg-muted mt-2" style="width: 1px;"></div>
                                        <div class="d-block d-lg-none bg-muted mb-2" style="height: 1px; margin-right: 1.2rem;"></div>
                                    </div>
                                    <div class="col-lg">
                                        <div class="flight-ticket-retrun">
                                            
            
                                            <div class="row gx-2 flex-nowra justify-content-between">
                                                <div class="col-lg-auto col-sm-auto col-12 d-flex align-items-cente flex-column">
                                                    <div class="return-place-from">${ flight.placeToName }</div>
                                                    ${ flight.timeReturnFrom ? '<div class="return-time-from" data-flight-{{ $flightUnitId }}-TimeReturnFrom>' + flight.timeReturnFrom + '</div>' : '' }
                                                    
                                                </div>
                                                <div class="col-sm-auto col d-sm-flex flex-column justify-content-cente align-items-center">
                                                    <i class="las la-arrow-right text-muted la-lg mt-lg-1 my-2"></i>
                                                </div>
                                                <div class="col-lg-auto col-sm-auto col-12 text-sm-end d-flex align-items-cente flex-column">
                                                    <div class="return-place-to">${ flight.placeFromName }</div>
                                                    ${ flight.timeReturnTo ? '<div class="return-time-to" data-flight-{{ $flightUnitId }}-TimeReturnTo>' + flight.timeReturnTo + '</div>' : '' }

                                                </div>
                                            </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="ticket-tail">
                            <div class="ticket-tail-check">
                                <i class="las la-check la-lg"></i>
                            </div>
                        </div>
                    </div>
                </div>
            `);
        });
    },

    renderPeriods() {
        let $calendarWraper = $('.booking-calendar-wrapper');
        this.dayAndMonthsData = $calendarWraper.attr('data-daysandmonths');
        this.dayAndMonthsData = this.dayAndMonthsData ? JSON.parse(this.dayAndMonthsData) : null;
        const departures = JSON.parse($('#details-datepicker').attr('data-departures'));
        $calendarWraper.append(`<div id="booking-calendar-slider" class="keen-slider d-flex" style="z-index: 11;">`
        + ( departures.map((departure) => { return this.fillDepartureWithData(departure) }) ).join('')
        + `</div> <div class="keen-slider__arrows d-none">
                <button class="btn btn-light bg-white rounded-circle p-2" data-dir="prev">
                    <i class="las la-angle-left text-muted la-lg"></i>
                </button>
                <button class="btn btn-light bg-white rounded-circle p-2" data-dir="next">
                    <i class="las la-angle-right text-muted la-lg"></i>
                </button>
            </div>` )

        this.initPeriodSlider($calendarWraper.find('#booking-calendar-slider'));
    },

    fillDepartureWithData(departure) {
        let date = new Date(departure.dateFrom);
        let month = this.getMonth(date);
        let day = this.getDay(date);
        let monthShort = month.substring(0, 3);
        let year = date.getFullYear();
        let dayOfMonth = ('0' + date.getDate()).slice(-2);

       return `<div class="keen-slider__slide">
            <div class="departure pointer bg-white rounded m-2 mb-3 px-3 py-2 text-center position-relative"
                data-dateFrom="${ departure.dateFrom }"
                data-dateTo="${ departure.dateTo }"
                data-aDateFrom="${ departure.aDateFrom }"
                data-aDateTo="${ departure.aDateTo }"
                data-duration="${ departure.duration }"
                data-time="${ departure.time }">
                <div class="small text-dark"><small>${ year }.</small></div>
                <div class="fw-bold h6 text-dark d-xl-block d-none my-2 mt-3">${ month }</div>
                <div class="mb-0 fw-bold text-dark d-xl-none">${ monthShort }</div>
                <div class="h2 mt-1 mb-2 fw-bold text-dark">${ dayOfMonth }</div>
                <div class="small mt-1 text-dark"><small>${ departure.nights } noći</small></div>
                <div class="small text-dark fw-bold">${ day }</div>
            </div>
        </div>`
    },
    initPeriodSlider($bookingCalendar)
    {
        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 7;
            
            let slidesPerView = length < perView ? length : perView;
            
            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-12');
                $('#booking-calendar-slider').addClass('justify-content-xl-center');
                slidesPerView = 7;
                // $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 3));
                // $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    

            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 10,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 4.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.2,
                        },
                    },
                },
            });

            

        }
    },

    getMonth(date) {
        let monthIndex = date.getMonth();
        return this.dayAndMonthsData[0][monthIndex+1];
    },
    getDay(date) {
        let dayIndex = date.getDay();
        let day = this.dayAndMonthsData[1][dayIndex+1];
        return day.charAt(0).toUpperCase() + day.slice(1);
    },
    initDefault() 
    {
        let $elem = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-duration=' + this.params.duration +']');

        if (! $elem.length) $elem = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
        
        $elem.addClass('active');

        $elem.parent().addClass('active');

        this.params.dateFrom = $elem.attr('data-datefrom');
        this.params.dateTo = $elem.attr('data-dateto');
        this.params.aDateTo = $elem.attr('data-adateto') || $elem.attr('data-dateto');
        this.params.aDateFrom = $elem.attr('data-adatefrom') || $elem.attr('data-datefrom');
        this.params.time = $elem.attr('data-time') || '';
        this.params.duration = $elem.attr('data-duration') || '';

        functions.setUrlData(
            {
                'dateFrom': this.params.dateFrom, 
                'dateTo': this.params.dateTo, 
                'aDateFrom': this.params.aDateFrom, 
                'aDateTo': this.params.aDateTo, 
                'guests': this.params.guests,
                'serviceCode': this.params.serviceCode, 
                'duration': this.params.duration || '', 
                'time': this.params.time || ''
            }, 
            true, 
            true);
        

        this.slider.instance.moveToIdx($elem.parent().index());
        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            $target.parent().addClass('active');

            this.params.dateFrom = $target.attr('data-datefrom');
            // $('[data-datefrom=' + this.params.dateFrom + '][data-duration=' + this.params.duration +']').addClass('active');


            this.params.dateTo = $target.attr('data-dateto');
            this.params.aDateTo = $target.attr('data-adateto') || $target.attr('data-dateto');
            this.params.aDateFrom = $target.attr('data-adatefrom') ||$target.attr('data-datefrom');
            this.params.time = $target.attr('data-time');
            this.params.duration = $target.attr('data-duration');

            this.printDates();

            functions.setUrlData(
                {
                    'dateFrom': this.params.dateFrom, 
                    'dateTo': this.params.dateTo, 
                    'aDateFrom': this.params.aDateFrom, 
                    'aDateTo': this.params.aDateTo, 
                    'guests': this.params.guests,
                    'serviceCode': this.params.serviceCode, 
                    'duration': this.params.duration || '', 
                    'time': this.params.time || ''
                }, 
                true, 
                true);

        });
    }


};
