if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    const { RawHTML } = window.wp.element;

    const { 
        RichText,
        InspectorControls,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
        CheckboxControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/socials-section', {
        // built-in attributes
        title: 'Socials section',
        description: 'Socials section',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            upperText: {
                type: 'string',
                default: 'Budi u toku s našim avanturama',
            },
            lowerText: {
                type: 'string',
                default: 'Prati nas na društvenim mrežama'
            },
            showLogo: {
                type: 'boolean',
                default: false
            }
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                upperText,
                lowerText,
                showLogo
            } = attributes;

            // custom functions
            function onUpperTextChange (upperText) {
                setAttributes({upperText: upperText});
            };

            function onShowLogoChange (show) {
                setAttributes({showLogo: show});
            };

            function onLowerTextChange(lowerText) {
                setAttributes({lowerText: lowerText});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Upper text</strong></p>
                        <TextControl value={ upperText } onChange={ onUpperTextChange }></TextControl>
                        <br />

                        <p><strong>Lower text</strong></p>
                        <TextControl value={ lowerText } onChange={ onLowerTextChange }></TextControl>
                        <br />

                        <p><strong>Show logo</strong></p>
                        <CheckboxControl
                            __nextHasNoMarginBottom
                            label="Show logo"
                            checked={ showLogo }
                            onChange={ onShowLogoChange }
                            />
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="row logo-section">
                    <div class={"col-auto logo-wrap " + ( ! showLogo ? 'd-none' : '')}>
                        <img src="/wp-content/uploads/component-logo.png"></img> 
                    </div>
                    <div class="col">
                        <div class="row text-wrap">
                            <div class="col-12 upper-text">
                                <RichText 
                                    key="editable" 
                                    tagName="div"
                                    className="upper-text"
                                    placeholder="Preporučujemo" 
                                    value={ upperText }
                                    onChange={ onUpperTextChange } />
                            </div>
                            <div class="col-12 lower-text">
                                <RichText 
                                    key="editable" 
                                    tagName="div"
                                    className="lower-text"
                                    placeholder="Aktualna ponuda" 
                                    value={ lowerText }
                                    onChange={ onLowerTextChange } />
                            </div>

                        </div>
                    </div>
                </div>
            ]);
        },

        save({ attributes }) {

            const {
                upperText,
                lowerText,
                showLogo
            } = attributes;

            return (
                <div class="socials-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                    <div className="upper-text">
                                        {upperText }
                                    </div>
                                    <div className="lower-text">
                                        <RawHTML>
                                            { lowerText }
                                        </RawHTML>
                                    </div>
                                    [social_links]
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
}


