if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
        TextareaControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-banner-horizontal', {
        // built-in attributes
        title: 'Card Banner Horizontal',
        description: 'Custom Card Banner Horizontal',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            }, 
            tag: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'Pogledajte ponudu'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                image,
                alt,
                tag,
                title,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({image: image.sizes.full.url});
            }

            function onAltChange(alt) {
                setAttributes({alt: alt});
            }

            function onTagChange(tag) {
                setAttributes({tag: tag});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                        <br />
                        <p><strong>Alt image:</strong></p>
                        <TextControl value={ alt } onChange={ onAltChange }></TextControl>
                        <br />
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <br />
                        <p><strong>Oznaka</strong></p>
                        <TextControl value={ tag } onChange={ onTagChange }></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <br />
                        <p><strong>Description</strong></p>
                        <TextareaControl value={ description } onChange={ onDescriptionChange }></TextareaControl>
                        <br />
                        <p><strong>Link</strong></p>
                        <TextControl value={ viewMore } onChange={ onViewMoreChange }></TextControl>

                    </PanelBody>
                </InspectorControls>
                ,
                <a class="card card-banner card-banner-horizontal">
                    <div class="card-img-top">
                        <img class="cover-image" src={ image } alt={ alt } />
                        <RichText 
                            key="editable" 
                            tagName="div"
                            className="card-tag"
                            placeholder="OZNAKA" 
                            value={ tag }
                            onChange={ onTagChange } />
                    </div>
                    <div class="card-body">
                        <RichText 
                            key="editable" 
                            tagName="div"
                            className="description"
                            placeholder="DESCRIPTION" 
                            value={ description }
                            onChange={ onDescriptionChange } />
                        <div class="title-container">
                            <RichText 
                                key="editable" 
                                tagName="h4"
                                className="title"
                                placeholder="TITLE" 
                                value={ title }
                                onChange={ onTitleChange } />
                        </div>
                        <div class="cta">
                            <RichText 
                                key="editable" 
                                tagName="div"
                                className="cta-content"
                                placeholder="Pogledajte ponudu" 
                                value={ viewMore }
                                onChange={ onViewMoreChange } />
                        </div>
                    </div>
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                image,
                alt,
                tag,
                title,
                description,
                viewMore
            } = attributes;

            return (
                <a href={ link } className="card card-banner card-banner-horizontal">
                    <div className="card-img-top">
                        <img className="lozad cover-image" data-src={ image } alt={ alt } />
                        <div className={ tag ? "card-tag" : "d-none" }>{ tag }</div>
                    </div>
                    <div className="card-body">
                        <div className="description">{ description }</div>
                        <div className="title-container">
                            <h4 className="title">{ title }</h4>
                        </div>
                        <div className="cta">
                            <div className="cta-content">{ viewMore }</div>
                        </div>
                    </div>
                </a>
            );
        },
    });
}