import functions from "../functions.js";
let globalEventsInitiated = false;
let id = 0;
let suppressClose = false;
const instances = {};

const FormGuideDefaults = {
	maxItemsPerColumn: 7,
	// to do some logic on hiding dropdown, return false to prevent change event on input
	onHide: () => {
		return true;
	},
	dropdownTpl: (label_search) => {
		return `
			<div class="form-guide-dropdown" data-fgid="${id}">

				<div class="form-guide-search mb-3">
					<input class="form-control form-guide-control" tabindex="-1" placeholder="${label_search}">
				</div>

				<div class="row align-items-center gx-2 mb-1">
					<div class="col">
						<div class="form-guide-level-action btn btn-sm btn-light"></div>
					</div>
					<div class="col-auto">
						<button type="button" class="btn btn-light" data-dir="-1" tabindex="-1">
							<i class="la la-arrow-alt-circle-left la-2x"></i>
						</button>
					</div>
					<div class="col-auto">
						<button type="button" class="btn btn-light" data-dir="1" tabindex="-1">
							<i class="la la-arrow-alt-circle-right la-2x"></i>
						</button>
					</div>
				</div>

				<div class="row form-guide-items flex-column flex-md-row"></div>

			</div>
		`;
	},
	loaderTpl: () => {
		return `
			<div class="d-flex flex-column align-items-center justify-content-center">
				<span class="form-guide-loader"></span>
			</div>
		`;
	},
	columnTpl: () => {
		return `
			<div class="form-guide-column col-12 col-md-6 col-lg-4 col-xl-3">
		`;
	},
	itemTpl: (item, a) => {
		return `
			<div class="form-guide-item">
				<a  ${item.url ? 'href="' + item.url + '"' : 'data-itemId="' + item.id + '"'}
					class="form-guide-item-select btn btn-sm btn-light" target="_blank"
				>
					${item.url ? '<i class="la la-external-link-alt la-lg me-2"></i>' : ''}
					${item.text}
				</a>
				<a  ${item.items && a ? 'data-itemId="' + item.id + '"' : ''}
					class="form-guide-item-browse btn btn-sm btn-light"
				>
					<i class="la la-plus"></i>
				</a>
			</div>
		`;
	},
	itemGroupTpl: item => {
		return `
			<div class="form-guide-item-group fw-bold my-3">${item.text || ''}</div>
		`;
	},
	selectionTpl: item => {
		return `${item ? item.text : ''}`;
	},
	placeholder: '&nbsp',
	label_search: 'Start typing to search...',
	label_browse: 'Browse destinations',
	label_searchResults: 'Search results',
	label_noSearchResults: 'Nothing matching your term',
	skipRegion: true,
	showRegionsForCountry: true,
	showRivierasForCountry: false,
	showOnlyCountries: true,
};

class FormGuide {

	constructor(elem, options = {}) {
		this.id = ++id;
		instances[this.id] = this;

	  	this.elem = elem;
	  	this.$elem = $(elem).attr('data-fgid', this.id);
		this.$input = $(`<input type="hidden" name="${this.$elem.attr('name')}">`).insertBefore(elem);
		this.$elem.removeAttr('name');
		this.$container = this.$elem.closest('.form-guide-container');
		this.setOptions(options);
		this.$elem.attr('placeholder', this.placeholder);
		this.$dropdown = $(this.dropdownTpl(this.label_search)).appendTo(this.$container);
		this.$levelAction = this.$dropdown.find('.form-guide-level-action');
		this.$items = this.$dropdown.find('.form-guide-items');
		this.$arrows = this.$dropdown.find('[data-dir]');
		this.$search = this.$dropdown.find('.form-control');


		this.loading = $.Deferred();

		this.setEvents();
		this.setSelection();
		
		this.$input[0].guide = this;
	}

	setOptions(options) {
		if ( !functions.isObject(options) ) {
			options = {};
		}

		this.placeholder = options.placeholder || this.$elem.attr('data-placeholder') || FormGuideDefaults.placeholder;
		this.maxItemsPerColumn = options.maxItemsPerColumn || this.$elem.attr('data-maxItemsPerColumn') || FormGuideDefaults.maxItemsPerColumn;
		this.maxItemsPerColumn = +this.maxItemsPerColumn || FormGuideDefaults.maxItemsPerColumn;

		this.label_search = options.label_search || this.$elem.attr('data-label_search') || FormGuideDefaults.label_search;
		this.label_browse = options.label_browse ||this.$elem.attr('data-label_browse') || FormGuideDefaults.label_browse;
		this.label_searchResults = options.label_searchResults || this.$elem.attr('data-label_searchResults') || FormGuideDefaults.label_searchResults;
		this.label_noSearchResults = options.label_noSearchResults || this.$elem.attr('data-label_noSearchResults') || FormGuideDefaults.label_noSearchResults;

		this.dropdownTpl = options.dropdownTpl || FormGuideDefaults.dropdownTpl;
		this.loaderTpl = options.loaderTpl || FormGuideDefaults.loaderTpl;
		this.columnTpl = options.columnTpl || FormGuideDefaults.columnTpl;
		this.itemTpl = options.itemTpl || FormGuideDefaults.itemTpl;
		this.itemGroupTpl = options.itemGroupTpl || FormGuideDefaults.itemGroupTpl;
		this.selectionTpl = options.selectionTpl || FormGuideDefaults.selectionTpl;
		this.onHide = options.onHide || FormGuideDefaults.onHide;

		this.searchParams = options.searchParams || JSON.parse(this.$elem.attr('data-searchParams') || '{}');
		this.searchParams = this.searchParams || {};
		!this.searchParams.lang && (this.searchParams.lang = functions.getLang());

		this.skipRegion = functions.parseIfBool(options.skipRegion || this.$elem.attr('data-skipRegion') || FormGuideDefaults.skipRegion);
		this.showRegionsForCountry = functions.parseIfBool(options.showRegionsForCountry || this.$elem.attr('data-showRegionsForCountry') || FormGuideDefaults.showRegionsForCountry);
		this.showRivierasForCountry = functions.parseIfBool(options.showRivierasForCountry || this.$elem.attr('data-showRivierasForCountry') || FormGuideDefaults.showRivierasForCountry);
		this.showOnlyCountries = functions.parseIfBool(options.showOnlyCountries || this.$elem.attr('data-showOnlyCountries') || FormGuideDefaults.showOnlyCountries);

		if ( this.skipRegion )  {
			this.showRegionsForCountry = false;
		}
	}

	setEvents() {
		// global handler for show/hide instances
		if ( !globalEventsInitiated ) {
			globalEventsInitiated = true;
			$(document)
				.on('mouseup focusin', e => {
					const $target = $(e.target);
					const fgid = +$target.attr('data-fgid') || +$target.closest('.form-guide-dropdown[data-fgid]').attr('data-fgid');
					const curInstance = fgid ? instances[fgid] : null;
					// prevent reaction to focusin on searchfield
					if ( curInstance && e.type == 'focusin' && e.target.tagName == 'INPUT' ) {
						return;
					}
					// if mousedown was on searchfield and up is outside, prevent close
					if ( suppressClose ) {
						suppressClose = false;
						return;
					}
					// click outside any instance -> close all
					if ( !curInstance ) {
						$.map(instances, instance => instance.hide());
						return;
					}
					// click inside any instance -> close all others
					if ( $target.is(curInstance.$elem) ) {
						$.map(instances, instance => {
							instance.id != curInstance.id && instance.hide()
						});
						// fix show/hide race...
						setTimeout(() => curInstance.show(), 100);
					}
				})
				.on('keydown', e => {
					// hide dropdown on escape
					e.which == 27 && $.map(instances, instance => instance.hide());
					// preserve natural tab order
					if ( e.which == 9 ) {
						$.each(instances, (i, instance) => {
							if ( instance.shown ) {
								const $formItems = instance.$elem.closest('form').find('[name]');
								let focusIndex = 0;
								$formItems.each((i, elem) => {
									if ( elem == instance.$input[0] ) {
										focusIndex = i + (e.shiftKey ? -1 : 1);
									}
								});

								if ( focusIndex > -1 ) {
									const $focusElem = $formItems.eq(focusIndex);
									if ( $focusElem.length ) {
										e.preventDefault();
										if ( $focusElem.attr('tabindex') < 0 ) {
											$focusElem.parent().find('[tabindex]').focus();
										} else {
											$focusElem.focus();
										}
									}
								}
								return false;
							}
						});
					}
				});
		}
		// scroll by click on arrows
		this.$arrows.on('click', e => {
			e.stopImmediatePropagation();
			this.scrollItems(+$(e.currentTarget).attr('data-dir'));
		});
		// scroll by swipe
		this.$items.on('mousedown mousemove mouseup touchstart touchmove touchend', e => {
			const dir = functions.getSwipeDirection(e);
			dir != 0 && this.scrollItems(dir);
		});
		// select item
		this.$items.on('click', '.form-guide-item-select', e => {
			e.stopImmediatePropagation();
			this.$search.val('');
			if ( e.currentTarget.href ) {
				setTimeout(() => this.hide(), 100);
			} else {
				const itemId = $(e.currentTarget).attr('data-itemId');
				itemId && this.selectItem(itemId);
			}
		});
		// browse item
		this.$items.on('click', '.form-guide-item-browse', e => {
			e.stopImmediatePropagation();
			const item = this.findItem($(e.currentTarget).attr('data-itemId'));
			if ( item ) {
				this.showLoader();
				this.actionDelay().done(() => this.buildItems(item));
			}
		});
		// back one lvl up
		this.$levelAction.on('click', e => {
			e.stopImmediatePropagation();
			const itemId = this.$levelAction.attr('data-parentId');
			if ( typeof itemId != 'undefined' ) {
				const item = this.findItem(itemId);
				this.showLoader();
				this.actionDelay().done(() => this.buildItems(item));
			}
		});
		// react on outer input change
		this.$input.on('change', () => {
			if ( !this.settingValue ) {
				let inputValue = this.$input.val() || null;
				if ( inputValue != (this.selectedItem ? this.selectedItem.id : null) ) {
					this.selectItem(inputValue, false);
				}
			}
		});
		// search field
		this.searchTimeout;
		this.$search.on('input', () => {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				const searchTerm = this.$search.val();
				if ( searchTerm.length > 2 ) {
					this.search(searchTerm);
				}
				else if ( searchTerm.length == 0 ) {
					this.searchMode = false;
					this.loadData();
				}
			}, 500);
		});
		// fix for triggering hide from click outside too much aggressively
		this.$dropdown.on('mousedown', () => {
			suppressClose = true;
		});
	}

	// data related
	showLoader() {
		if ( this.shown && !this.$loader ) {
			this.$loader = $(this.loaderTpl());
			this.$items.html(this.$loader);
		}
	}
	hideLoader() {
		if ( this.shown && this.$loader ) {
			this.$loader.remove();
			this.$loader = null;
		}
	}
	actionDelay(delay) {
		const def = $.Deferred();
		setTimeout(() => {
			def.resolve();
		}, delay || 250);
		return def.promise();
	}
	loadData() {
		this.showLoader();

		return $.when(
			$.ajax({
				method: 'post',
				dataType: "json",
				url: '/services/guide/',
				data: this.searchParams
			}),
			this.actionDelay()
		).done(response => {
			this.hideLoader();
			this.data = (response || [])[0];

			if( ! this.searchParams.dynamicPackage && this.searchParams.objectGroupId == 1 && this.searchParams.excludeAccCountryIds) {

				this.data.destinations.items = this.data.destinations.items.filter((destination, index) => {
					return ! this.searchParams.excludeAccCountryIds.includes(+destination.id);
				});

			}

			this.setDataParentIds(this.data.destinations);
			this.shown && this.buildItems();
			setTimeout(() => this.loading.resolve());
		})
		.fail(() => {
			this.hideLoader();
			setTimeout(() => this.loading.resolve());
		});
	}
	setDataParentIds(parentItem) {
		parentItem && parentItem.items.forEach(item => {
			item.parentId = parentItem.id;
			item.items && this.setDataParentIds(item);
		});
	}
	search(search) {
		this.searchMode = true;
		this.showLoader();
		return $.when(
			$.ajax({
				method: 'post',
				dataType: "json",
				url: '/services/guide/',
				data: $.extend({}, this.searchParams, { search: search })
			}),
			this.actionDelay()
		).done(response => {
			this.hideLoader();
			this.buildSearchItems((response || [])[0]);
		})
		.fail(() => {
			this.hideLoader();
		});
	}
	buildItems(parentItem) {
		parentItem = parentItem || this.data.destinations;
		if ( this.showOnlyCountries && parentItem.reference == 'country' ) {
			return this.buildItems();
		}
		if ( parentItem.reference == 'country' ) {
			if ( parentItem.showRegions == false/* || parentItem.items.length < 2*/ ) {
				this.items = $.map(parentItem.items, region => {
					region.showRegion = false;;
					if ( region.showRivieras == false/* || region.items.length < 2*/ ) {
						return $.map(region.items, riviera => riviera.items);
					} else {
						return region.items;
					}
				});
			} else {
				this.items = parentItem.items || this.findItem(parentItem.parentId).items;
			}
		}
		else if ( parentItem.reference == 'region' ) {
			// if ( parentItem.showRegion === false ) { // country
			// 	return this.buildItems(this.findItem(parentItem.parentId));
			// }
			// if ( parentItem.showRivieras == false/* || parentItem.items.length < 2*/ ) {
			// 	this.items = $.map(parentItem.items, riviera => riviera.items);
			// } else {
			// 	this.items = parentItem.items || this.findItem(parentItem.parentId).items;
			// }
		}
		else {
			this.items = parentItem.items || this.findItem(parentItem.parentId).items;
		}

		const _items = [];

		this.items.sort((a,b) => (a.sortBy || a.text).localeCompare(b.sortBy || b.text));
		this.items.forEach((item, i) => {
			if ( i%this.maxItemsPerColumn == 0 ) {
				_items.push(this.columnTpl());
			}
			_items.push(this.itemTpl(item, ! this.showOnlyCountries));
			if ( i%this.maxItemsPerColumn == this.maxItemsPerColumn-1 ) {
				_items.push('</div>');
			}
		});
		this.hideLoader();
		this.$items.html(_items.join(''));
		this.setLevelAction();
		this.checkArrowsVisibility();
		this.moveIntoViewport();
	}
	buildSearchItems(data) {
		const _items = [];
		if (this.showOnlyCountries && data.destinations)
		{
			
			data.destinations.items = data.destinations.items.length && data.destinations.items.filter(item => {
				return item.reference == 'country';
			})
			if ( ! data.destinations.items.length ) delete data.destinations;
		}
		const groupKeys = Object.keys(data);
		
		if ( groupKeys.length ) {
			// flatten group title item with group items
			const flatGroups = [];
			groupKeys.forEach(group => {
				flatGroups.push([{ group: group, text: data[group].text }].concat(data[group].items.sort((a,b) => a.similarity > b.similarity)));
			});
			// "smart" organise to columns

			const columns = [[]];
			let curColumn;
			flatGroups.forEach(group => {
				curColumn = columns[columns.length-1];
				// whole group fits to current column? (aka already 4 + try to fit 3)
				if ( curColumn.length + group.length <= this.maxItemsPerColumn ) {
					curColumn.push.apply(curColumn, group);
				}
				// else fit manually
				else {
					// create new column if previous is populated
					if ( curColumn.length ) {
						curColumn = [];
						columns.push(curColumn);
					}
					group.forEach(item => {
						if ( curColumn.length == this.maxItemsPerColumn ) {
							curColumn = [{ group: true }];
							columns.push(curColumn);
						}
						curColumn.push(item);
					});
				}
			});
			// build html
			columns.forEach(column => {
				_items.push(this.columnTpl());
				column.forEach(item => {
					if ( item.url && this.searchParams.dynamicPackage && ( this.searchParams.dynamicPackage == 1 || this.searchParams.dynamicPackage == 2 ) ) {
						item.url += '?dynamicPackage=' + this.searchParams.dynamicPackage;
					}
					if( ! this.searchParams.dynamicPackage && this.searchParams.objectGroupId == 1 && this.searchParams.excludeAccCountryIds) {
						if (item.id && this.searchParams.excludeAccCountryIds.includes(+item.id)) return;
					}
					_items.push(item.group ? this.itemGroupTpl(item) : this.itemTpl(item, true));
				});
				_items.push('</div>');
			});
		}

		this.items = _items;
		this.hideLoader();
		this.$items.html(_items.join(''));
		this.setLevelAction();
		this.checkArrowsVisibility();
		this.moveIntoViewport();
	}
	setLevelAction() {
		const firstItem = this.items && this.items[0];
		if ( this.searchMode ) {
			this.$levelAction.text(firstItem ? this.label_searchResults : this.label_noSearchResults);
			this.$levelAction.removeAttr('data-parentId');
		}
		else {
			let parentItem = this.findItem(firstItem.parentId);
			if ( parentItem && parentItem.reference == 'region' ) {
				let country = this.findItem(parentItem.parentId);
				if ( country.showRegions == false/* || country.items.length < 2*/ ) {
					parentItem = country;
				}
			}
			if ( parentItem && parentItem.reference == 'riviera' ) {
				let region = this.findItem(parentItem.parentId);
				if ( region.showRivieras == false/* || region.items.length < 2*/ ) {
					parentItem = region;
					let country = this.findItem(parentItem.parentId);
					if ( country.showRegions == false/* || country.items.length < 2*/ ) {
						parentItem = country;
					}
				}
			}
			if ( parentItem ) {
				let parentParent = parentItem.parentId ? this.findItem(parentItem.parentId) : null;
				if ( parentParent && parentParent.reference == 'region' && parentParent.showRegion === false ) {
					parentParent = this.findItem(parentParent.parentId);
				}
				this.$levelAction
					.text(`${parentParent ? parentParent.text + ' / ' : ''}${parentItem.text}`)
					.attr('data-parentId', parentItem.parentId || '');
			} else {
				this.$levelAction
					.text(this.label_browse)
					.removeAttr('data-parentId');
			}
		}
	}
	// data related END

	// show/hide related
	show() {
		if ( this.shown ) {
			return;
		}

		this.shown = true;
		// add shown class and dynamically calculate top position
		let topOffset = this.$elem[0].offsetTop + this.$elem[0].offsetHeight;
		this.$dropdown.addClass('form-guide-dropdown-shown').css('top', topOffset + 10);
		$(document.body).addClass('form-guide-dropdown-shown');
		// reset scroll to 0 so items always start from left
		this.resetScroll();
		this.data ? this.buildItems(this.selectedItem) : this.loadData();
		// focus search field

		if ( !('ontouchstart' in window) ) {
			setTimeout(() => this.$search.focus(), 100);
		}
	}
	hide() {
		if ( !this.shown ) {
			return;
		}

		const canHide = this.onHide();
		if ( canHide ) {
			this.shown = false;
			this.searchMode = false;
			this.$search.val('');
			this.$dropdown.removeClass('form-guide-dropdown-shown');
			$(document.body).removeClass('form-guide-dropdown-shown');
		}
	}
	// move dropdown into viewport (if search form is near bottom of screen)
	moveIntoViewport () {
		setTimeout(() => {
			if ( this.$dropdown[0].getBoundingClientRect().bottom > window.innerHeight ) {
				this.$dropdown[0].scrollIntoView(false);
			}
		}, 200);
	}
	// show/hide related END

	// scroll related
	scrollItems(dir) {
		// scroll items to direction for whole of its visible width
		// like 4 columns on xl; 1 column on xs...
		this.$items[0].scrollTo({
			left: this.$items[0].scrollLeft + (this.$items[0].clientWidth * dir),
			behavior: 'smooth'
		});
		// adjust direction arrows (visibility, disabled)
		setTimeout(() => this.checkArrowsVisibility(), 800);
	}
	resetScroll() {
		this.$items[0].scrollTop = 0;
		this.$items[0].scrollLeft = 0;
		this.checkArrowsVisibility();
	}
	checkArrowsVisibility() {
		const scrolled = this.$items[0].scrollLeft;
		// if less items so no scroll - hide arrows
		this.$arrows.toggleClass('invisible', (this.$items[0].scrollWidth - 20) <= this.$items[0].clientWidth);
		// disable prev or next if at beginning or end
		const width = this.$items[0].scrollWidth - this.$items[0].clientWidth - 100; // just in case
		this.$arrows.first().prop('disabled', !scrolled);
		this.$arrows.last().prop('disabled', scrolled >= width);
	}
	// scroll related END

	// selection related
	selectItem(itemId, trigger) {
		// if not data loaded, load it and call self again
		if ( !this.data ) {
			return this.loadData().done(() => this.selectItem(itemId, trigger));
		}
		let item = itemId ? this.findItem(itemId) : null;
		this.selectedItem = item;
		this.setSelection();
		trigger !== false && this.setValue(this.selectedItem && this.selectedItem.id);
		this.hide();
	}
	findItem(itemId) {
		return this.data.destinations && this.data.destinations.items && this.findInItems(this.data.destinations.items, itemId);
	}
	findInItems(items, itemId) {
		return $.map(items, item => {
			if ( item.id == itemId ) {
				return item;
			}
			else if ( item.items ) {
				return this.findInItems(item.items, itemId);
			}
		})[0];
	}
	setSelection() {
		this.$elem.html(this.selectionTpl(this.selectedItem));
	}
	setValue(value) {
		this.$input[0].value = value;
		this.settingValue = true;
		this.$input.trigger('change');
		this.settingValue = false;
	}
	// selection related END

	// for public use
	getValue() {
		return this.$input[0].value;
	}
}

export {
	FormGuide,
	FormGuideDefaults
};
