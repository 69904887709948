import _default from "keen-slider";

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/content-slide'];

    registerBlockType('custom-gutenberg/content-slider', {
        // built-in attributes
        title: 'content Slider',
        description: 'Custom content Slider',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // built-in functions
        edit() {

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/content-slide', {} ],
            ];

            return (
                <div class="content-slider position-relative">
                    <div id="content-slider" class="keen-slider" style={ { opacity: '1' } }>
                        <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                    </div>
                    <div class="keen-slider__pager">
                        <div class="keen-slider__pager-buttons"></div>
                    </div>
                </div>
            );
        },

        save() {
            let rng = Math.floor(Math.random() * 10000);
            return (
                <div className="content-slider position-relative" >
                    <div id={ "content-slider-" + rng } className="keen-slider">
                        <InnerBlocks.Content />
                    </div>
                    <div className="keen-slider__pager">
                        <div className="keen-slider__pager-buttons"></div>
                    </div>
                    <div className="keen-slider__arrows">
                            <div ref="" data-dir="prev"><i className="las la-angle-double-left"></i></div>
                            <div ref="" data-dir="next"><i className="las la-angle-double-right"></i></div>
                        </div>
                </div>
            );
        },
    });
}