import functions from "./functions.js";

$(function () {

    const $form = $('.form-contact');
    const form = $form[0];

    if (!form) {
        return;
    }

    $form.on('submit', e => {
        e.preventDefault();

        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        params['language'] = functions.getLang();       

        $form.find('.btn-contact')
            .prop('disabled', true)
            .find('.la-spinner').removeClass('d-none');
        
        grecaptcha.ready(function () {
            grecaptcha.execute('6LfRYVwqAAAAAMKVV70XP9KTqRjmIxi-O8CW1DRd', { action: 'contact' }).then(function (token) {
                params['gRecaptchaToken'] = token;
                params = functions.getDataForUrl(params);
                //console.log(params);
                $.post('/services/contact/', params).then(response => {
                    //console.log(response);
                    response = JSON.parse(response);

                    $form.find('.btn-contact')
                        .prop('disabled', false)
                        .find('.la-spinner').addClass('d-none');

                    if ( response.status == true ) {
                        response.data = '<i class="la la-check-circle la-3x me-4"></i>' + response.data;
                        $form.find(".response-output").removeClass('d-none').addClass('border-secondary').html(response.data);
                        form.reset();
                    } else {
                        response.data = '<i class="la la-exclamation-circle la-3x me-4"></i>' + response.data;
                        $form.find(".response-output").removeClass('d-none').addClass('border-danger').html(response.data);
                    }

                    setTimeout(() => {
                        $form.find(".response-output").addClass('d-none').removeClass('border-secondary border-danger').html('');
                    }, 6000);
                });
            });
        });

    });

});