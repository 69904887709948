import functions from '../functions';
import { pricelistCalculation } from '../details/pricelist-calculation';

$(() => {
    details.init();
})

const details = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        details.$details = $('.page-type-details');

        if(! details.$details[0]) {
            return;
        }

        $('[id^=pricelist-tab]').on('show.bs.tab', e => {
            const $target = $(e.currentTarget);
            let params = JSON.parse($target.attr('data-params'));
 
            const $unitPricelist = $('.unit-pricelist-' + params.unitId);
            const $unitPricelistSpinner = $('.unit-pricelist-spinner-' + params.unitId);
 
            if(! $unitPricelist.children().length) {
                $.get('/services/get_pricelists/', params).done(response => {
                    $unitPricelist.html(response);
                    $unitPricelistSpinner.addClass('d-none');

                    const tabTargetId = $target.attr('data-bs-target').replace('#', '');
                    const $tabTarget = $('.tab-pane[id="' + tabTargetId + '"]');
                    pricelistCalculation.init($tabTarget);
                })
            }
        });

        $('[id^=pricelistTableModal]').on('show.bs.modal', e => {
            const $target = $(e.currentTarget);
            let params = $target.attr('data-params') ? JSON.parse($target.attr('data-params')) : null;

            const $objectPricelist = $('.object-pricelist');
            const $objectPricelistSpinner = $('.object-pricelist-spinner');
 
            if(params && ! $objectPricelist.children().length) {
                $.get('/services/get_pricelists/', params).done(response => {
                    $objectPricelist.html(response);
                    $objectPricelistSpinner.addClass('d-none');

                    pricelistCalculation.init($target);
                })
            }
        });


    },
    getParams(params, defaults = {}) {
        return $.extend({}, params, defaults, functions.getUrlData(true));
    },
    isBookable(data, rateId = null) {
        if(rateId) {
            return (data.booking.status == 'Request' || data.booking.status == 'Reservation') && data.isAvailable && data.calc.status == 'Active';
        }
        return data.booking.status == 'Request' || data.booking.status == 'Reservation';
    },
    getCalcErrorMessage(messages, data) {
        if (data.message && messages && messages[data.message]) {
            return messages[data.message].replace('{$condition}', data.condition);
        }
        return messages ? messages.general : null;
    },
    getCalculation(params) {

        const objectGroupId = params.objectGroupId;
        const objectId = params.objectId || params.publicId;
        const unitId = params.unitId;
        const dateFrom = params.dateFrom;
        const dateTo = params.dateTo ? params.dateTo : dateFrom;
        const guests = params.guests && params.guests.startsWith('0') ? null : params.guests;
        const dynamicPackage = params.dynamicPackage;
        const flightId = params.flightId;
        const flightUnitId = params.flightUnitId;
        const pricelistId = params.pricelistId;
        const optionals = params.optionals;
        const serviceCode = params.serviceCode;
        const aDateFrom = params.aDateFrom;
        const aDateTo = params.aDateTo;
        const partnerId = params.partnerId;

        if(
            (objectId || unitId)
            && guests 
            && dateFrom 
            && dateTo 
            && objectGroupId
        ) {
            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + (unitId ? '?unitId=' + unitId : '?publicId=' + objectId)
                + '&guests=' + guests
                + '&dateFrom=' + dateFrom
                + '&dateTo=' + dateTo
                + '&objectGroupId=' + objectGroupId
                + '&language=' + functions.getLang()
                + (dynamicPackage ? '&dynamicPackage=' + dynamicPackage : '')
                + (flightUnitId ? '&flightUnitId=' + flightUnitId : '')
                + (pricelistId ? '&pricelistId=' + pricelistId : '')
                + (optionals ? '&optionals=' + optionals : '')
                + (serviceCode ? '&serviceCode=' + serviceCode : '')
                + (aDateFrom ? '&aDateFrom=' + aDateFrom : '')
                + (aDateTo ? '&aDateTo=' + aDateTo : '')
                + (partnerId ? '&partnerId=' + partnerId : '')
                + (params.time ? '&time=' + params.time : '')
                + '&addAllOptionals=true'
            );
        } else {
            return null;
        }

    },
    getCalcTotalOld(calcItems) {
        let priceOld = 0;

        calcItems.forEach(item => {
            // popust za djecu ne ulazi u prikaz discount cijene
            if((item.itemType != 'discount' || item.itemDefinitionId == 129) && !item.optional && !item.onSpot) {
                priceOld += item.itemTotal;
            }
        });

        return priceOld;
    },
    getCalcDiscountTitle(calcItems) {

        let discountTitle = null

        calcItems.forEach(item => {
            if(item.itemType == 'discount') {
                discountTitle = item.itemTitle;
                if(item.data && item.data.text) {
                    discountTitle = item.data.text;
                }
            }
        });
        
        return discountTitle;
    },
};

export {
    details,
};
