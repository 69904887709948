if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        RichText,
        InspectorControls
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
        CheckboxControl,
        SelectControl
    } = window.wp.components;

    const {
        useState
    } = window.wp.element;

    const { 
        useBlockProps 
    } = window.wp.blockEditor;

    registerBlockType('custom-gutenberg/tab-button', {
        // built-in attributes
        title: 'Tab Button',
        description: 'Tab',
        icon: 'slides',
        category: 'custom-tabs',
        example: {},
        parent: ['custom-gutenberg/nav-tabs'],

        // custom attributes
        attributes: {
            id: {
                type: 'string',
                default: '',
            },
            title: {
                type: 'string',
                default: '',
            },
            active: {
                type: 'boolean',
                default: false,
            },
            badge: {
                type: 'string',
                default: '',
            },
        },

        edit ({ attributes, setAttributes }) {

            const {
                id,
                title,
                active,
                badge
            } = attributes;

            // custom functions
            const [ isCheched, setChecked ] = useState( active ? active : false );
            setAttributes({active: isCheched});

            function onTitleChange (title) {
                setAttributes({title: title});
            };

            function onIdChange (id) {
                setAttributes({id: id});
            };

            function onIconChange(badge) {
                setAttributes({badge: badge});
            }

            const blockProps = useBlockProps( {
                className: 'nav-link' + (active ? ' active' : ''),
            } );

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Tab settings' }>
                        <p><strong>ID must be unique</strong></p>
                        <TextControl value={ id } onChange={ onIdChange }></TextControl>
                        
                        <p><strong>Icon</strong></p>
                        <SelectControl label="select icon" 
                        value={ badge } 
                        options= { [
                            { label: '', value:''},
                            { label: 'Car', value:'las la-car'},
                            { label: 'Bus', value: 'las la-bus'},
                            { label: 'Plane', value: 'las la-plane'},
                            { label: 'Trip', value: 'las la-globe'},
                        ] }
                        onChange={ ( onIconChange ) }
                        
                        />

                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>

                        <CheckboxControl
                            label="Is default active tab"
                            help="Only ONE tab can be default active!"
                            checked={ isCheched }
                            onChange={ setChecked  }
                        />
                    </PanelBody>
                </InspectorControls>,
                <button { ...blockProps } id={ id + "-tab"} data-bs-toggle="tab" data-bs-target={ "#" + id } type="button" role="tab">
                    <RichText 
                        key="editable" 
                        tagName="span" 
                        placeholder="Tab" 
                        value={ title }
                        onChange={ onTitleChange } />
                </button>
            ]);
        },

        save ({ attributes }) {

            const {
                id,
                title,
                active,
                badge
            } = attributes;

            return ( 
                <button className={ "nav-link" + (active ? ' active' : '' ) } id={ id + "-tab" } data-bs-toggle="tab" data-bs-target={ "#" + id } type="button" role="tab">
                    <i className={ badge ? badge + ' nav-tab-icon' : 'd-none' }></i>
                    <span> { title } </span>
                </button>
            );
        },
    });
}