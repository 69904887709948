if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls
    } = window.wp.editor;

    const {
        PanelBody,
        TextControl,
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-box', {
        // built-in attributes
        title: 'Card Box',
        description: 'Custom Card Box',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            icon: {
                type: 'string',
                default: 'la la-check',
            },
            title: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                icon,
                title
            } = attributes;

            // custom functions
            function onIconChange(icon)
            {
                setAttributes({ icon: icon });
            }

            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Settings'}>
                        <p><strong>Icon</strong></p>
                        <TextControl value={icon} onChange={onIconChange}></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>
                        <br />
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="card card-box">
                    <div class="card-body text-center">
                        <i class={ "mb-3 " + icon }></i>
                        <RichText
                            key="editable"
                            tagName="h4"
                            className="card-title"
                            placeholder="TITLE"
                            value={title}
                            onChange={onTitleChange} />
                    </div>
                </div>
            ]
            );
        },

        save({ attributes })
        {

            const {
                icon,
                title
            } = attributes;

            return (
                <div className="card card-box">
                    <div className="card-body text-center">
                        <i className={'mb-3 ' + icon}></i>
                        <h4 className="card-title">{title}</h4>
                    </div>
                </div>
            );
        },
    });
}