if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        InnerBlocks,
    } = window.wp.editor;

    const {
        PanelBody,
        SelectControl,
        TextControl,
    } = window.wp.components;

    registerBlockType('custom-gutenberg/bullet-info', {
        // built-in attributes
        title: 'Bullet info',
        description: 'Bullet info',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            icon: {
                type: 'string',
                default: 'la-landmark',
            },
            link: {
                type: 'string',
                default: '',
            },
            title: {
                type: 'string',
                default: ''
            },
            text: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                icon,
                link,
                title,
                text,
                viewMore,
            } = attributes;

            // custom functions
            function onIconChange(icon)
            {
                setAttributes({ icon: icon });
            }

            function onLinkChange(link)
            {
                setAttributes({ link: link });
            }

            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            function onTextChange(text)
            {
                setAttributes({ text: text });
            }

            function onViewMoreChange(viewMore)
            {
                setAttributes({ viewMore: viewMore });
            }

            const MY_TEMPLATE = [
                ['core/paragraph', {}],
                ['core/paragraph', {}],
            ];

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Settings'}>
                        <p><strong>Icon</strong></p>
                        <SelectControl
                            value={icon}
                            options={[
                                { label: 'Landmark', value: 'la-landmark' },
                                { label: 'Archway', value: 'la-archway' },
                                { label: 'Calendar', value: 'la-calendar' },
                                { label: 'Heart hand', value: 'la-hand-holding-heart' },
                                { label: 'Badge', value: 'la-certificate' },
                                { label: 'User shield', value: 'la-user-shield' },
                            ]}
                            onChange={onIconChange}
                        />
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>
                        <br />
                        <p><strong>Text</strong></p>
                        <TextControl value={text} onChange={onTextChange}></TextControl>
                        <br />
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="bullet-info">
                    <InnerBlocks template={MY_TEMPLATE} />
                </div>
            ]
            );
        },

        save({ attributes })
        {

            const {
            } = attributes;

            return (
                <div className="bullet-info">
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}