import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;

    registerBlockType('custom-gutenberg/sabra-badge', {
        // built-in attributes
        title: 'Sabra badge',
        description: 'Sabra badge',
        icon: '',
        category: 'sabra-badge',
        example: {},

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            return (
                <div class={ "sabra-badge " + classes }>
                </div>
            );
        },

        save({ attributes }) {

            const {
                classes
            } = attributes;

            return (
                <div className="sabra-badge">
                </div>
            );
        },
    });
}